import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Button from "~/components/button";
import Accordion from "~/components/accordion/accordion";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={7}
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 7</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Now that you are familiar with both clinical trials, think about why one trial might be a better experiment than the other.</p>
              <p>Be ready to share your ideas in a class discussion.</p>
              <p>Do you want to add anything else to the list you made earlier about good experimental design?</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Hold a class discussion to debrief the comparison of the two studies. Students should recognize that the DPP is the stronger and more rigorous study. They are also likely to be somewhat frustrated that they cannot find answers to all the questions on the handout, but this can be a clue as to which is a stronger study.</p>
              <p>One of the key things they will probably notice is that the DPP study involved many more participants than the green coffee bean extract study (3,234 vs. 16 participants). From the reading on clinical trials, students should recognize that 16 is a very low number of participants and would be appropriate for the early stages of a trial when researchers are investigating safety issues and side effects-not the effectiveness of a treatment.</p>
              <p>The fact that the DPP study was carried out for 2.8 years vs. 22 weeks for the green coffee bean extract study is another indication of the better design of the DPP study. Twenty-two weeks is a short amount of time for participants to go through 3 phases (doses of treatment or placebo) and washout periods between dosing phases.</p>
              <p>The inclusion of a reference to a full, explicitly detailed research protocol in the DPP reading is another indication of quality, as is its registration in ClinicalTrials.gov. The fact that very little information about the protocol or review by an IRB is in the green coffee bean extract reading weakens the information.</p>
              <p>Most students should recognize that the DPP was a stronger study because it included a large number of participants overall and because it included people from a wide variety of ages, demographic groups, body weights, and sexes. You can inform students that this was a very successful clinical trial. In fact, the data from the DPP were so strong that researchers concluded that the data from a third year of the study were not necessary to answer their research question.</p>
              <p>ClinicalTrials.gov is a website where researchers as well as the public can learn about clinical trials currently underway in the United States and around the world. The website is maintained by the National Institutes of Health. ClinicalTrials.gov does not contain information about all the clinical studies conducted in the United States because not all studies are required by law to be registered (for example, observational studies and trials that do not study a drug, biologic, or device).</p>

              <Accordion headerText="Note to teachers" className="my-4" open={true}>
                <p className="mt-0">The green coffee bean extract study example was chosen for several reasons. First, it was widely covered on TV during the last several years. It also provides students with opportunities to detect problematic aspects of information found on a website. In some ways, these problems make the good qualities of a study like the DPP appear more obvious. Lastly, the goal is for students to become skeptical about information they encounter and to think critically about what is presented rather than just accept it because a website owner claims that a product has been clinically tested or has an MD after their name. Acknowledge that a health-conscious person does not have the time or energy to delve deeply into every health claim that they see. But when a particular heath issue is of personal importance, a health literate person knows what questions to ask and how to evaluate evidence when seeking answers to those questions.</p>
              </Accordion>

              <div>
                <h2>Step 7A</h2>
                <p><strong>Teacher only screen:</strong> Show the green coffee bean extract study website to students again. Ask them to raise their hand if they wrote that seeing the words "clinical study" or "clinical trial" on the website is something that indicated accuracy (Step 3). Ask if they would react differently to those words now.</p>

                <Button
                  action="secondary"
                  className="my-2"
                  color="purple"
                  href="/green-coffee-magic"
                  icon="external"
                  title="Green coffee bean extract website"
                >
                  Green coffee bean extract website
                </Button>

                <p>Inform students that scientists have continued to assess the paper that was published about the green coffee bean extract study. The scientists have found enough problems with the study that the authors have now retracted the paper. A paper is retracted when there are serious problems such as scientific misconduct, plagiarism, pervasive errors, or unsubstantiated data. If you wish, you can display the paper with a "RETRACTED" mark on it. You can explain that retraction is another way that scientists work to make sure results of studies are accurate. Click below to display the paper.</p>

                <Button
                  action="secondary"
                  className="my-2"
                  color="purple"
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3267522/"
                  icon="external"
                  title="Retracted paper"
                >
                  Retracted paper
                </Button>

                <p className="italic">The green coffee bean extract study was included in this lesson because it sometimes is more striking to point out what is bad than what is good (and probably more fun for students). However, only a small percentage of scientific papers are retracted each year (approximately four of every 10,000 papers). Some studies speculate that the number of retractions is increasing because papers are under more intense scrutiny now than they have been in the past.</p>
                <p>If you wish, share the reading <em>Problems with the Green Coffee Bean Extract Study</em> with students, which identifies specifically some of the problems with the study that led to its retraction. This may also help confirm some of the questions that students had when they read about the study.</p>

                <Button
                  action="secondary"
                  className="my-2"
                  color="purple"
                  href="/files/hs/lesson_2/Problems_with_the_green_coffee_bean_extract_study-scl.final.aw.pdf"
                  icon="download"
                  title="Problems with the green coffee bean extract study"
                >
                  Problems with the green coffee bean extract study
                </Button>

                <p>If students question how they would really be able to tell whether a study was done well or not, you can confirm that this is a difficult issue. One thing people can do is look at the ClinicalTrials.gov website. Most people do not have time to search all the medical literature for answers. References provided on a product's website can also be checked (searched online) to see if there are any updates related to that product. Also, they can ask a health professional (doctor, nurse, pharmacist) for information. But perhaps one thing that everyone can do is develop a level of skepticism and be willing to raise questions. Have students heard the old expression, "Don't believe everything you see (or hear)?" Do they understand how it might apply in this situation?</p>
                <p>If you would like more information about the green coffee bean extract and the coverage it received in the media, click on the following link to read more information about the issues with this supplement.</p>

                <Button
                  action="secondary"
                  className="my-2"
                  color="purple"
                  href="/files/hs/lesson_2/Green_Coffee_Bean_Extract_in_the_Media-scl.final.pdf"
                  icon="download"
                  title="Green coffee bean extract in the media"
                >
                  Green coffee bean extract in the media
                </Button>
              </div>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
